<template>
  <base-section
    id="how-security"
    class="pt-0"
  >
    <div
      style="background-color: rgb(145, 217, 229);"
      class="pt-8"
    >
      <base-section-heading
        color="white"
        :title="$i18n.t('t0.title')"
        :text="$i18n.t('t0.text')"
        icon="fas fa-history"
        dark
        class="pt-6"
      />
      <base-section-heading
        color="white"
        :title="$i18n.t('t1.title')"
        :text="$i18n.t('t1.text')"
        icon="fas fa-spray-can"
        dark
        class="pb-6"
      />
    </div>

    <v-container>
      <base-section-heading
        color="primary lighten-2"
        :title="$i18n.t('t2.title')"
        :text="$i18n.t('t2.text')"
      />
      <v-row class="d-flex justify-center">
        <p class="pb-6 text-h6 px-3 text-center">{{ $t(`promo.p0`) | uppercase}}</p>
      </v-row>
      <div class="d-flex justify-center">
        <v-row
          class="d-flex justify-center"
          style="max-width: 1000px;"
        >
          <v-col
            :style="`min-width: ${$vuetify.breakpoint.smAndDown ? '150px' : '200px'} !important; max-width: 200px !important;`"
            v-for="(service, i) of services"
            :key="i"
            class="d-flex align-center justify-start flex-column"
          >
            <base-avatar-card
              v-bind="service"
              space="0"
            >
              <p class="text-center">{{ $t(`s${i}.title`) | uppercase }}</p>
            </base-avatar-card>
          </v-col>
        </v-row>
      </div>
      <v-row class="d-flex justify-center">
        <p class="text-h6 text-center">{{ $t(`promo.p1`) | uppercase}}</p>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',
    data () {
      return {
        services: [
          {
            color: 'primary',
            dark: true,
            icon: 'restaurant',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'menu_book',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'emoji_emotions',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'accessible_forward',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'wc',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'celebration',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'sports_volleyball',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'bedroom_baby',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'rowing',
          },
          {
            color: 'primary',
            dark: true,
            icon: 'sailing',
          },
        ],
      }
    },
  }
</script>
<i18n>
{
	"en": {
		"t0": {
			"text": "The following project is the  result of the collaboration of two firms, SGD Group and Minnovi, respectively a firm dedicated to pest control and a software house specialised in administrative solutions on cloud. \n\nThe aim behind this project is being  able to combine the knowledge of two firms in order to tangibly help our territory, especially during this difficult time due to the sanitary emergency caused by Covid19.",
			"title": "The story"
		},
		"t1": {
			"text": "The sanitisation is an important treatment aimes at guaranting the objects disinfection. Beyond the pandemic emergency, this type of treatment is essential to remove virus and bacteria that could endanger the individual. ",
			"title": "Why is it important to sanitise?"
		}
	},
	"it": {
		"t0": {
			"text": "Questo progetto nasce dalla collaborazione di due aziende, SGD Group e Minnovi, rispettivamente un’azienda di pest control e una software house specializzata in soluzioni gestionali in cloud. L’idea nasce dal volere unire le proprie conoscenze per realizzare un servizio di tracciamento delle sanificazioni in spiaggia, che renda traspsarente agli ospiti dello stabilimento la modalità e le tempistiche degli interventi, e che possa altresì permettere allo stabilimento balneare di promuovere i servizi offerti",
			"title": "La storia"
		},
		"t1": {
			"text": "La sanificazione è un trattamento importante per garantire la disinfezione degli oggetti e quindi l’eliminazione di virus e di batteri per la sicurezza della persona. Nel momento in cui si effettua una disinfezione professionale e questa viene tracciata e resa pubblica, i bagnanti non hanno alcun motivo di preoccupazione e possono godersi la spiaggia in totale serenità",
			"title": "Perchè sanificare"
		},
		"t2": {
			"title": "Non solo... promuovi anche i tuoi servizi",
			"text": "Con la semplice scansione del codice QR affisso sull'ombrellone o in altre postazioni da te scelte, l'ospite della tua spiaggia si collegherà ad un sito dedicato al tuo stabilimento, in cui vedrà, oltre all'ultima sanificazione effettuata, i servizi disponibili da te scelti!"
		},
    "t3": {
      "title": "Perfetto per la promozione di"
    },
		"s0": {
			"title": "Bar, chioschi e ristoranti"
		},
		"s1": {
			"title": "Menù"
		},
		"s2": {
			"title": "Animazione"
		},
		"s3": {
			"title": "Servizi speciali"
		},
		"s4": {
			"title": "Toilette e docce"
		},
		"s5": {
			"title": "Eventi"
		},
		"s6": {
			"title": "Campi sportivi"
		},
		"s7": {
			"title": "Area giochi"
		},
    "s8": {
      "title": "Noleggio attrezzature"
    },
    "s9": {
      "title": "Sport acquatici"
    },
    "promo": {
      "p0": "Perfetto per la promozione di",
      "p1": "E molto altro!"
    }
	}
}
</i18n>
